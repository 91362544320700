/* Component Dependencies */
var transactionalFooterLinksTemplate = require('templates/transactionalFooterLinks.hbs');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');

AriesComponent.create({
  type: 'transactionalFooterLinks',
  template: {
    'transactionalFooterLinks': transactionalFooterLinksTemplate
  }
});
